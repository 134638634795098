<template>
    <div class="judge">
        <el-dialog title="提示" :visible.sync="dialogVisible" width="350px" :before-close="handleClose">
            <span class="label">是否为自己答题？</span>
            <span slot="footer" class="dialog-footer">
                <el-button  @click="ownerToQuestion" size="small">否</el-button>
                <el-button type="primary" @click="toEvaluation" size="small">是</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false
        };
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        openMo() {
            this.dialogVisible = true;
            // localStorage.setItem('patientId', 'null');
        },
        toEvaluation() {
            localStorage.setItem('patientId', 'null');
            this.$router.push('/home/evaluation');
        },
        ownerToQuestion() {
            this.dialogVisible = false;
            localStorage.setItem('patientId', 'null');
            
        }
    }
};
</script>

<style lang="scss" scoped>
 ::v-deep.judge{
    .el-dialog__footer{
        text-align: center !important;
    }
}
.judge{
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    .label{
        font-size: 18px;
    }
}
</style>