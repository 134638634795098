<template>
  <div class="record">
    <!-- 预约咨询 -->
    <div class="content">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="nickname">
          <span class="label">姓名</span>
          <br>
          <el-input v-model="ruleForm.nickname"></el-input>
        </el-form-item>
        <!-- <el-form-item prop="sex">
          <span class="label">性别</span>
          <br>
          <el-select v-model="ruleForm.sex" placeholder="请选择性别">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item prop="telephone">
          <span class="label">手机号</span>
          <br>
          <el-input v-model="ruleForm.telephone" type="number"></el-input>
        </el-form-item>

        <!-- <el-form-item prop="birthday">
          <span class="label">出生日期</span>
          <br>
          <el-date-picker v-model="ruleForm.birthday" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item> -->
        <!-- <el-form-item prop="username">
          <span class="label">登录名</span>
          <br>
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <span class="label">登录密码</span>
          <br>
          <el-input v-model="ruleForm.password" type="password" show-password></el-input>
        </el-form-item> -->
      </el-form>
      <div class="btn">
        <!-- <button @click="toStep">跳过</button> -->
        <button @click="submitForm('ruleForm')">开始测评</button>
      </div>
    </div>
    <JudgeUserVue ref="child"></JudgeUserVue>
  </div>
</template>

<script>
import { registerUser } from '@/api/record'
import { getUserId } from '../../api/evaluation'
import JudgeUserVue from './JudgeUser.vue';
export default {
  data() {
    return {
      ruleForm: {
        // username: null,
        telephone: null,
        // source: 2,
        // sex: null,
        // password: null,
        nickname: null,
        // birthday: null
      },
      rules: {
        nickname: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 15, message: '长度 15 个字符以内', trigger: 'change' }
        ],
        // sex: [
        //   { required: true, message: '请选择性别', trigger: 'change' }
        // ],
        // birthday: [
        //   { type: 'date', required: true, message: '请选择出生日期', trigger: 'change' }
        // ],
        telephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号格式', trigger: 'change' }
        ],
        // username: [
        //   { required: true, message: '请输入登录名', trigger: 'blur' },
        //   { max: 10, message: '长度在 10 个字符以内', trigger: 'change' }
        // ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'blur' }
        // ]
      }
    };
  },
  components: { JudgeUserVue },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await getUserId(this.ruleForm.nickname, this.ruleForm.telephone);
          console.log(res);
          if (res.code === 200) {
            // let info = JSON.parse(localStorage.getItem('userInfo'))
            // // console.log()
            // info.id = res.data;
            localStorage.setItem('patientId', res.data);
            // this.$message({
            //   showClose: true,
            //   message: '注册成功',
            //   type: 'success'
            // });
            setTimeout(() => {
              this.$router.push('/home/evaluation')
            }, 1000);
          } else {
            this.$message({
              showClose: true,
              message: res.msg
            });
          }
          // alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toStep() {
      this.$router.push('/home/evaluation')
    }
  },
  mounted() {
    this.$refs.child.openMo();
    // this.$confirm('是否为自己答题?', '提示', {
    //   confirmButtonText: '是',
    //   cancelButtonText: '否',
    //   type: 'warning',
    //   center: true,
    //   showClose: false
    // }).then(async () => {
    //   localStorage.setItem('patientId', 'null');
    //   this.$router.push('/home/evaluation');
    // }).catch(() => {
    // });
  },
  created() {
    // localStorage.setItem('patientId', 'null')
  }
}
</script>

<style lang="scss" scoped>
.record {
  // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
  .content {
    width: 80%;
    margin: auto;
    padding-top: 40px;

    .el-form {
      span {
        font-size: 16px;
      }

      .el-select,
      .el-date-picker,
      .el-input {
        width: 100%;
      }
    }
  }

  .btn {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    button {
      cursor: pointer;
      width: 100%;
      height: 40px;
      background: var(--custom-color);
      border-radius: 4px;
      border: none;
      color: white;
      font-size: 14px;
    }
  }
}
</style>